export default [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
    meta: {
      layout: "UserLayout",
      auth: true,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "tandc",
    component: () =>
      import(
        /* webpackChunkName: "Terms and Conditions" */ "../views/TermsConditions.vue"
      ),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacypolicy",
    component: () =>
      import(
        /* webpackChunkName: "Privacy Policy" */ "../views/PrivacyPolicy.vue"
      ),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/refund-policy",
    name: "refundpolicy",
    component: () =>
      import(
        /* webpackChunkName: "Refund Policy" */ "../views/RefundPolicy.vue"
      ),
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/contests/category/:category",
    name: "categorizedEvents",
    component: () =>
      import(
        /* webpackChunkName: "categorizedEvents" */ "../views/CategorizedEvents.vue"
      ),
    props: true,
    meta: {
      layout: "UserLayout",
      auth: false,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
  {
    path: "/auth/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: "/auth/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/RegisterView.vue"),
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
    meta: {
      layout: "UserLayout",
      auth: true,
      canOrganizerManage: false,
      onlyAdmin: false,
    },
  },
];
